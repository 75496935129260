import React, { Component } from "react";
import { connect } from "react-redux";
import { addQuestion } from "../../services";
import { startLoader, stopLoader } from "../../store/reducers/generalActions";
import { notifyError, notifySuccess } from "../../utils/Helper";
import _ from "lodash";
// medium mcq red line

class Addmediummcq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newquestions: {
        question: [],
        options: ["", "", "", ""],
        category_type: 2,
        content_id: props.questions[0].content_id,
        topic: props.questions[0].topic,
      },
      titleofQuestion_emsg: "",
      option_emsg: "",
      selectedOption: null,
      isEmptyIndex: null,
      optionIndex: null,
      isOptionEmpty: null,
    };
  }

  async componentDidMount() {
    var optionObj = this.state.newquestions.options;
    optionObj.map((option, index) => {
      if (option.endsWith("*")) {
        this.setState({
          optionIndex: index,
        });
      }
    });
  }

  validatemainquestion = () => {
    var que = this.state.newquestions.question;
    if (que.length == 0) {
      this.setState({
        isEmptyIndex: 1,
        titleofQuestion_emsg: "Please enter text in the input field.",
      });
      return false;
    }

    for (var i = 0; i < que.length; i++) {
      if (que[i].trim() == "") {
        this.setState({
          isEmptyIndex: i + 1,
          titleofQuestion_emsg: "Please enter text in the input field.",
        });
        return false;
      }
    }

    if (!que[0].endsWith("?")) {
      this.setState({
        isEmptyIndex: 1,
        titleofQuestion_emsg: "Please put ? in the end.",
      });
      return false;
    }

    if (que[0].trim().length == 1) {
      this.setState({
        titleofQuestion_emsg: "Please Enter an actual question.",
      });
      return false;
    }

    if (que.length <= 5) {
      this.setState({
        isEmptyIndex: que.length + 1,
        titleofQuestion_emsg: "Please enter text in the input field.",
      });
      return false;
    }

    this.setState({
      titleofQuestion_emsg: "",
    });

    return true;
  };

  validateoption = () => {
    var opt = this.state.newquestions.options;
    var option = opt.map((val) => val.replace("*", ""));

    for (var i = 0; i < option.length; i++) {
      if (option[i].trim() == "") {
        this.setState({
          isOptionEmpty: i + 1,
          option_emsg: "Please Enter the option",
        });
        return false;
      }
    }
    if (!this.state.optionIndex) {
      this.setState({
        option_emsg: "Please mark the correct answer.",
      });
      return false;
    }

    this.setState({
      option_emsg: "",
    });

    return true;
  };

  add = (event) => {
    this.setState({
      titleofQuestion_emsg: "",
      isEmptyIndex: null,
    });

    var newquestion = this.state.newquestions;
    var index = event.target.name;
    newquestion.question[index - 1] = event.target.value;
    this.setState({
      newquestions: newquestion,
    });
  };

  optionChange = (e) => {
    this.setState({
      isOptionEmpty: null,
      option_emsg: "",
    });
    let index = e.target.name;
    var optionValue = [...this.state.newquestions.options];
    var val = optionValue[index - 1];
    if (val.endsWith("*")) {
      optionValue[index - 1] = e.target.value + " *";
    } else {
      optionValue[index - 1] = e.target.value;
    }

    var newQuestionObj = Object.assign({}, this.state.newquestions);
    newQuestionObj.options = optionValue;

    this.setState({
      newquestions: newQuestionObj,
    });
  };

  radioButtonValue = (event) => {
    // event.prevent.default()
    this.setState({
      optionIndex: event.target.name,
      selectedOption: event.target.value,
    });
  };

  discard = () => {
    this.props.discardquestion("mediummcq");
  };

  handlesubmit = () => {
    if (!this.validatemainquestion() || !this.validateoption()) {
      return false;
    }

    var reqObj = _.cloneDeep(this.state);
    const data = this.state;
    reqObj.newquestions.question[0] =
      "Ques  : " + data.newquestions.question[0];

    if (this.state.optionIndex != null) {
      var optionsObj = reqObj.newquestions.options;
      var opt = optionsObj.map((val) => val.replace("*", ""));
      opt[this.state.optionIndex - 1] = opt[this.state.optionIndex - 1] + " *";
      reqObj.newquestions.options = opt;
    }

    this.doSubmit(reqObj);
  };

  doSubmit = async (req) => {
    this.props.dispatch(startLoader());
    try {
      const { data: res } = await addQuestion(req);
      if (res.success) {
        notifySuccess(res.message);
        const allQuestions = res.response;
        this.props.parentCallback(allQuestions);
        this.discard();
      } else {
        notifyError(res.message);
      }
      this.props.dispatch(stopLoader());
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="each-question-wrapper-box">
          <div className="quetsion--edit">
            <div className="quetsion--edit--markquee">Q.</div>
            <input
              className={
                this.state.isEmptyIndex == 1
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="1"
              value={this.state.newquestions.question[0]}
              onChange={this.add}
              placeholder="Write your Question here..."
            />
          </div>
          <div className="quetsion--edit">
            <div className="quetsion--edit--markquee">I.</div>
            <input
              className={
                this.state.isEmptyIndex == 2
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="2"
              value={this.state.newquestions.question[1]}
              onChange={this.add}
              placeholder="Option 1"
            />
          </div>
          <div className="quetsion--edit">
            <div className="quetsion--edit--markquee">II.</div>
            <input
              className={
                this.state.isEmptyIndex == 3
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="3"
              value={this.state.newquestions.question[2]}
              onChange={this.add}
              placeholder="Option 2"
            />
          </div>
          <div className="quetsion--edit">
            <div className="quetsion--edit--markquee">III.</div>
            <input
              className={
                this.state.isEmptyIndex == 4
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="4"
              value={this.state.newquestions.question[3]}
              onChange={this.add}
              placeholder="Option 3"
            />
          </div>
          <div className="quetsion--edit">
            <div className="quetsion--edit--markquee">IV.</div>
            <input
              className={
                this.state.isEmptyIndex == 5
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="5"
              value={this.state.newquestions.question[4]}
              onChange={this.add}
              placeholder="Option 4"
            />
          </div>
          <div className="quetsion--edit">
            <div className="quetsion--edit--markquee"></div>
            <input
              type="text"
              name="6"
              value={this.state.newquestions.question[5]}
              onChange={this.add}
              className="quedtion--text--fields"
              placeholder="write your question"
            />
          </div>

          {this.state.titleofQuestion_emsg && (
            <span className="text-danger" style={{ marginLeft: "3em" }}>
              {this.state.titleofQuestion_emsg}
            </span>
          )}

          <div className="editable--questions">
            <div className="quetsion--edit--markquee">
              <label className="mordern-radiobox-label my-0">
                <input
                  type="radio"
                  name="1"
                  onChange={(e) => this.radioButtonValue(e)}
                  checked={this.state.optionIndex == 1 ? true : false}
                />
                <span className="design"></span>
              </label>
            </div>
            <input
              className={
                this.state.isOptionEmpty == 1
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="1"
              value={this.state.newquestions.options[0]}
              onChange={this.optionChange}
              placeholder="Option 1"
            />
          </div>
          <div className="editable--questions">
            <div className="quetsion--edit--markquee">
              <label className="mordern-radiobox-label my-0">
                <input
                  type="radio"
                  name="2"
                  onChange={(e) => this.radioButtonValue(e)}
                  checked={this.state.optionIndex == 2 ? true : false}
                />
                <span className="design"></span>
              </label>
            </div>
            <input
              className={
                this.state.isOptionEmpty == 2
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="2"
              value={this.state.newquestions.options[1]}
              onChange={this.optionChange}
              placeholder="Option 2"
            />
          </div>
          <div className="editable--questions">
            <div className="quetsion--edit--markquee">
              <label className="mordern-radiobox-label my-0">
                <input
                  type="radio"
                  name="3"
                  onChange={(e) => this.radioButtonValue(e)}
                  checked={this.state.optionIndex == 3 ? true : false}
                />
                <span className="design"></span>
              </label>
            </div>
            <input
              className={
                this.state.isOptionEmpty == 3
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="3"
              value={this.state.newquestions.options[2]}
              onChange={this.optionChange}
              placeholder="Option 3"
            />
          </div>
          <div className="editable--questions">
            <div className="quetsion--edit--markquee">
              <label className="mordern-radiobox-label my-0">
                <input
                  type="radio"
                  name="4"
                  onChange={(e) => this.radioButtonValue(e)}
                  checked={this.state.optionIndex == 4 ? true : false}
                />
                <span className="design"></span>
              </label>
            </div>
            <input
              className={
                this.state.isOptionEmpty == 4
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="4"
              value={this.state.newquestions.options[3]}
              onChange={this.optionChange}
              placeholder="Option 4"
            />
          </div>
          {this.state.option_emsg && (
            <span className="text-danger" style={{ marginLeft: "3em" }}>
              {this.state.option_emsg}
            </span>
          )}
          <div className="editable-question-submitbox">
            <button
              type="button"
              role="button"
              className="preview--paper--btn font--400 font--14 w-110"
              onClick={this.handlesubmit}
            >
              Add
            </button>
            <button
              role="button"
              type="putton"
              className="preview--button font--400 font--14 w-110 ml-2"
              onClick={this.discard}
            >
              Discard
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
};
export default connect(mapStateToProps)(Addmediummcq);
