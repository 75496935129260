import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { connect } from "react-redux";

class ToggleForGQ extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonType: props.tab,
    };

    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  handleButtonClick(buttonType) {
    if (buttonType !== "Standard") {
      localStorage.removeItem("hotsToggle");
    }

    console.log("clicked", buttonType);
    this.setState({ buttonType }, () =>
      console.log("state", this.state.buttonType)
    );
  }

  render() {
    const { buttonType } = this.state;
    return (
      <div
        className="mt-3 d-flex align-items-center"
        style={{
          position: "absolute",
          top: "10.89rem",
          left: "10.10rem",
          gap: "1rem",
        }}
      >
        {/* <label
          htmlFor="selectques_type"
          className="form-label d-flex text-white"
          style={{ fontWeight: 500, marginTop: "10px" }}
        >
          {" "}
          Choose Your Mode{" "}
        </label> */}

        <div className="easy-med-hard shadow-none">
          <Link
            className={`btn px-custom py-2 btn-prepai-green ${
              (buttonType === "Standard" || buttonType === "HOTS") &&
              "active-diff-btn border-radius-left-diff"
            }`}
            onClick={() => this.handleButtonClick("Standard")}
            to={
              localStorage.getItem("hotsToggle") == 1
                ? "/generate-questions-blooms"
                : "/generate-questions"
            }
            style={{ marginLeft: "0.45rem", padding: 0 }}
          >
            Content
          </Link>
          {/* <Link
            className={`btn px-custom py-2 toggle-gq ${
              buttonType === "HOTS" && "active-diff-btn"
            }`}
            onClick={() => this.handleButtonClick("HOTS")}
            to="/generate-questions-blooms"
          >
            HOTS
            <span
              className="badge badge-info beta-tag-toggle-gq"
            >
              BETA
            </span>
          </Link> */}
          {buttonType === "HOTS" && this.props.user.plan_details && (
            <div
              className="notify-tool-tip"
              style={{
                position: "absolute",
                // right: "28rem",
                // right: "28rem",
                right: "35rem",
                top: "9px",
              }}
            >
              <i
                className="fas fa-info-circle"
                style={{
                  fontSize: "16px",
                  color: "#08164e",
                  cursor: "pointer",
                }}
              ></i>
              {this.props.user.plan_details.plan_name == "Free" ? (
                <span
                  className="hoverText"
                  style={{ color: "#b6b1b1", backgroundColor: "#11267b" }}
                >
                  You've{" "}
                  {this.props.user.credits <= 0
                    ? "0 free credits"
                    : this.props.user.credits}{" "}
                  free credits worth{" "}
                  {this.props.user.credits > 0 &&
                    `$${_.round(this.props.user.credits / 2, 1)}`}{" "}
                  available in your account.
                  <br />
                  <span style={{ color: "white" }}>
                    Upgrade to PrepAI Premium
                  </span>{" "}
                  to get 54 free credits worth $27
                </span>
              ) : (
                <span
                  className="hoverText"
                  style={{ color: "#b6b1b1", backgroundColor: "#11267b" }}
                >
                  Credits Available:{" "}
                  {this.props.user.credits <= 0 ? 0 : this.props.user.credits}{" "}
                </span>
              )}
            </div>
          )}
          {/* | */}{" "}
          <Link
            className={`btn px-custom py-2 toggle-gq btn-prepai-green ${
              buttonType === "Special" && "active-diff-btn"
            }`}
            onClick={() => this.handleButtonClick("Special")}
            to="/subject-expertise-questions"
            style={{ borderRadius: "0px", marginLeft: "0.45rem" }}
          >
            Subject
            {/* <span
              className="badge badge-info beta-tag-toggle-gq"
            >
              BETA
            </span> */}
          </Link>
          {/* | */}{" "}
          {buttonType === "Special" && this.props.user.plan_details && (
            <div
              className="notify-tool-tip"
              style={{
                position: "absolute",
                // right: "14rem",
                right: "18rem",
                top: "9px",
              }}
            >
              <i
                className="fas fa-info-circle"
                style={{
                  fontSize: "16px",
                  color: "#08164e",
                  cursor: "pointer",
                }}
              ></i>
              {this.props.user.plan_details.plan_name == "Free" ? (
                <span
                  className="hoverText"
                  style={{ color: "#b6b1b1", backgroundColor: "#11267b" }}
                >
                  You've{" "}
                  {this.props.user.credits <= 0
                    ? "0 free credits"
                    : this.props.user.credits}{" "}
                  free credits worth{" "}
                  {this.props.user.credits > 0 &&
                    `$${_.round(this.props.user.credits / 2, 1)}`}{" "}
                  available in your account.
                  <br />
                  <span style={{ color: "white" }}>
                    Upgrade to PrepAI Premium
                  </span>{" "}
                  to get 54 free credits worth $27
                </span>
              ) : (
                <span
                  className="hoverText"
                  style={{ color: "#b6b1b1", backgroundColor: "#11267b" }}
                >
                  Credits Available:{" "}
                  {this.props.user.credits <= 0 ? 0 : this.props.user.credits}{" "}
                </span>
              )}
            </div>
          )}
          <Link
            className={`btn px-custom py-2 toggle-gq btn-prepai-green border-radius-right-diff ${
              buttonType === "Competitive" && "active-diff-btn"
            }`}
            onClick={() => this.handleButtonClick("Competitive")}
            to="/competition-level-questions"
            style={{ marginLeft: "0.45rem" }}
          >
            Standard Test
            {/* <span
              className="badge badge-info beta-tag-toggle-gq"
            >
              BETA
            </span> */}
          </Link>
          {buttonType === "Competitive" && this.props.user.plan_details && (
            <div
              className="notify-tool-tip"
              style={{
                position: "absolute",
                right: "1rem",
                top: "9px",
              }}
            >
              <i
                className="fas fa-info-circle"
                style={{
                  fontSize: "16px",
                  color: "#08164e",
                  cursor: "pointer",
                }}
              ></i>
              {this.props.user.plan_details.plan_name == "Free" ? (
                <span
                  className="hoverText"
                  style={{ color: "#b6b1b1", backgroundColor: "#11267b" }}
                >
                  You've{" "}
                  {this.props.user.credits <= 0
                    ? "0 free credits"
                    : this.props.user.credits}{" "}
                  free credits worth{" "}
                  {this.props.user.credits > 0 &&
                    `$${_.round(this.props.user.credits / 2, 1)}`}{" "}
                  available in your account.
                  <br />
                  <span style={{ color: "white" }}>
                    Upgrade to PrepAI Premium
                  </span>{" "}
                  to get 54 free credits worth $27
                </span>
              ) : (
                <span
                  className="hoverText"
                  style={{ color: "#b6b1b1", backgroundColor: "#11267b" }}
                >
                  Credits Available:{" "}
                  {this.props.user.credits <= 0 ? 0 : this.props.user.credits}{" "}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(ToggleForGQ);
