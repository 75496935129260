import React, { Component } from "react";
import { connect } from "react-redux";
import onClickOutside from "react-onclickoutside";
import { SaveEditQuestion } from "../../services";
import MSG, {
  notifySuccess,
  notifyError,
  notifyInfo,
  randomKey,
} from "../../utils/Helper";
import { startLoader, stopLoader } from "../../store/reducers/generalActions";
import { ConsoleView } from "react-device-detect";
import { trackEvent } from "../../utils/mixpanel";

class Editeasymcq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: this.props.data,
      alert: null,
      mainQuestion: this.props.data.question,
      titleofQuestion_emsg: "",
      option_emsg: "",
      selectedOption: null,
      optionIndex: null,
      isOptionEmpty: null,
      neutralize: false,
    };
  }

  async componentDidMount() {
    var optionObj = this.props.data.options;
    optionObj.map((option, index) => {
      if (option.endsWith("*")) {
        this.setState({
          optionIndex: index,
        });
      }
    });

    if (this.props.disable) {
      this.setState({
        neutralize: !this.state.neutralize,
      });
    }
  }

  handleClickOutside = (evt) => {
    this.props.showalert(
      this.state.question,
      this.state.mainQuestion,
      this.state.optionIndex
    );
  };

  handleChange = (e) => {
    this.setState({
      titleofQuestion_emsg: "",
      mainQuestion: e.target.value,
    });
  };

  validatemainquestion = () => {
    var que = this.state.mainQuestion;
    var question = que.replace("Ques  :", "");

    if (question.trim() == "") {
      this.setState({
        titleofQuestion_emsg: "Please enter the question",
      });
      return false;
    }

    if (!this.state.neutralize && this.state.question.question.endsWith("?")) {
      if (!question.endsWith("?")) {
        this.setState({
          titleofQuestion_emsg: "Please put ? in the end",
        });
        return false;
      }
    }

    this.setState({
      titleofQuestion_emsg: "",
    });
    return true;
  };

  validateoption = () => {
    var opt = this.state.question.options;
    var option = opt.map((val) => val.replace("*", ""));

    for (var i = 0; i < option.length; i++) {
      if (option[i].trim() == "") {
        this.setState({
          isOptionEmpty: i,
          option_emsg: "Please Enter the option",
        });
        return false;
      }
    }

    this.setState({
      option_emsg: "",
    });
    return true;
  };

  savechanges = async (e) => {
    e.preventDefault();
    // trackEvent("Save Edit Question Button Clicked", {
    //   source: this.props.addOnData.source,
    // });
    if (!this.validatemainquestion() || !this.validateoption()) {
      return false;
    }

    const changedquestion = { ...this.state.question };
    // changedquestion.question = [
    //   this.state.question.question + this.state.mainQuestion,
    // ];

    changedquestion.question = this.state.mainQuestion;

    this.setState({
      question: changedquestion,
    });

    if (this.state.optionIndex != null) {
      var optionsObj = changedquestion.options;
      var opt = optionsObj.map((val) => val.replace("*", ""));
      opt[this.state.optionIndex] = opt[this.state.optionIndex] + " *";
      changedquestion.options = opt;
    }

    if (this.state.neutralize) {
      this.props.handleQuestionUpdate(changedquestion);
      this.props.edit(e);
      return;
    }

    var que = changedquestion.question;
    var opt = changedquestion.options;
    var queId = changedquestion.question_id;

    var reqData = {
      questionId: queId,
      questions: que,
      options: opt,
      is_taxonomy: true,
    };

    if(this.props.is_sub_expertise) {
      reqData = {
        questionId: queId,
        questions: que,
        options: opt,
        is_sub_expertise: true,
      }
    }

    this.props.dispatch(startLoader());
    try {
      const { data: res } = await SaveEditQuestion(reqData);
      if (res.success == true) {
        // trackEvent("Edited Question Saved Successfully", {
        //   source: this.props.addOnData.source,
        // });
        notifySuccess(res.message);
        this.props.handleQuestionUpdate(changedquestion);
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.msg);
      } else if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.msg);
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.props.dispatch(stopLoader());
    this.props.edit(e);
  };

  optionchange = (e) => {
    this.setState({
      isOptionEmpty: null,
      option_emsg: "",
    });
    let index = e.target.name;
    let changedquestion = { ...this.state.question };
    var options = [...changedquestion.options];

    var val = options[index];
    if (val.endsWith("*")) {
      options[index] = e.target.value + " *";
    } else {
      options[index] = e.target.value;
    }

    changedquestion.options = options;
    this.setState({
      question: changedquestion,
    });
  };

  radioButtonValue = (event) => {
    this.setState({
      optionIndex: event.target.name,
      selectedOption: event.target.value,
    });
  };

  handlequestion = (e) => {
    var index = parseInt(e.target.name) + 1;
    var changedquestion = { ...this.state.question };
    changedquestion.question[index] = e.target.value;
    this.setState({
      question: changedquestion,
    });
  };

  cancelchanges = (e) => {
    e.preventDefault();
    this.props.edit(e);
  };

  render() {
    let option = this.state.question.options;
    // let question = this.state.question.question.slice(1);
    return (
      <React.Fragment>
        <div>
          <div id="editquestion" className="quetsion--edit">
            <div className="quetsion--edit--markquee">Q.</div>

            <input
              className={
                !this.state.titleofQuestion_emsg == ""
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              value={this.state.mainQuestion}
              onChange={this.handleChange}
            />

            {this.state.titleofQuestion_emsg && (
              <span className="text-danger">
                {this.state.titleofQuestion_emsg}
              </span>
            )}
            {/* {question.map((question, k) => {
              return (
                <input
                  type="text"
                  name={k}
                  key={randomKey()}
                  className="quedtion--text--fields"
                  defaultValue={question}
                  onChange={(e) => this.handlequestion(e)}
                />
              );
            })} */}
          </div>
          <div>
            {option.map((item, k) => {
              return (
                <div className="editable--questions" key={randomKey()}>
                  {option.length > 1 ? (
                    <>
                      <div className="quetsion--edit--markquee">
                        <label className="mordern-radiobox-label my-0">
                          <input
                            name={k}
                            type="radio"
                            value={item}
                            //defaultChecked={item.endsWith("*")}
                            checked={this.state.optionIndex == k ? true : false}
                            onChange={(e) => this.radioButtonValue(e)}
                          />
                          <span className="design"></span>
                        </label>
                      </div>

                      <input
                        className={
                          this.state.isOptionEmpty == k
                            ? "quedtion--text--fields input-required"
                            : "quedtion--text--fields"
                        }
                        name={k}
                        type="text"
                        onBlur={(e) => this.optionchange(e)}
                        defaultValue={
                          item.endsWith("*")
                            ? item.substring(0, item.length - 1)
                            : item
                        }
                      />
                    </>
                  ) : (
                    <input
                      className={
                        this.state.isOptionEmpty == k
                          ? "quedtion--text--fields input-required"
                          : "quedtion--text--fields"
                      }
                      name={k}
                      type="text"
                      onBlur={(e) => this.optionchange(e)}
                      defaultValue={
                        item.endsWith("*")
                          ? item.substring(0, item.length - 1)
                          : item
                      }
                    />
                  )}
                </div>
              );
            })}
            {this.state.option_emsg && (
              <span className="text-danger" style={{ marginLeft: 3 + "em" }}>
                {this.state.option_emsg}
              </span>
            )}
          </div>

          {/* <div className="editable--questions">
            <div className="quetsion--edit--markquee">
                <label className="mordern-radiobox-label my-0">
                    <input type="radio" name="questions" />
                    <span className="design"></span>
                </label>
            </div>
            <input type="text" className="quedtion--text--fields" value="The acceleration of an object is dependent upon two variables." />
        </div>
        <div className="editable--questions">
            <div className="quetsion--edit--markquee">
                <label className="mordern-radiobox-label my-0">
                    <input type="radio" name="questions" />
                    <span className="design"></span>
                </label>
            </div>
            <input type="text" className="quedtion--text--fields" value="The first law states that for every action (force) in nature there is an equal and opposite reaction." />
        </div>
        <div className="editable--questions">
            <div className="quetsion--edit--markquee">
                <label className="mordern-radiobox-label my-0">
                    <input type="radio" name="questions" />
                    <span className="design"></span>
                </label>
            </div>
            <input type="text" className="quedtion--text--fields" value="Force equals mass times acceleration. For every action, there is an equal and opposite reaction." />
        </div> */}
          <div className="editable-question-submitbox">
            <button
              type="button"
              role="button"
              className="preview--paper--btn font--400 font--14 w-110"
              onClick={this.savechanges}
            >
              Save
            </button>
            <button
              role="button"
              type="putton"
              className="preview--button font--400 font--14 w-110 ml-2"
              onClick={this.cancelchanges}
            >
              Cancel
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(onClickOutside(Editeasymcq));
