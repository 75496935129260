import React, { Component } from "react";
import moment from "moment";
import { sampleSize } from "lodash";
import { Link } from "react-router-dom";

class Salebar extends Component {
  state = {
    timeLeft: {},
    startSale: false,
    comingSoon: false,
  };

  // Convert local time to specific timezone
  convertDateTime = (date) => {
    return new Date(
      new Date(date).toLocaleString("en-US", {
        timeZone: "Asia/Kolkata",
      })
    );
  };

  calculateTimeLeft = () => {
    // Set Sale start date here
    var saleStartDate = new Date("Jul 3, 2023 9:29:59").getTime();

    saleStartDate = this.convertDateTime(saleStartDate);

    // Set the date we're counting down to(Sale end date)
    var countDownDate = new Date("Jul 7, 2023 9:29:59").getTime();
    // Convert local time to IST
    countDownDate = this.convertDateTime(countDownDate);

    // get today's date
    var today = new Date().getTime();
    today = this.convertDateTime(today);

    if (today >= saleStartDate) this.setState({ startSale: true });
    if (today >= countDownDate) this.setState({ startSale: false });
    // if (today < saleStartDate) this.setState({ comingSoon: true });

    // get the difference
    let diff = countDownDate - today;

    let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: undefined };

    if (diff > 0) {
      timeLeft = {
        // math
        days: Math.floor(diff / (1000 * 60 * 60 * 24)),
        hours: Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((diff % (1000 * 60)) / 1000),
      };
    }

    return timeLeft;
  };

  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState({
        timeLeft: this.calculateTimeLeft(),
      });
    }, 1000);
  }

  render() {
    return (
      <React.Fragment>
        {(this.state.comingSoon || this.state.startSale) && (
          <div className="row sale_bar">
            <div className="d-flex" style={{ gap: "1rem" }}>
              <img
                className="bunny-img"
                src={`${process.env.REACT_APP_PROJECT_URL}/img/american-flag.png`}
              />
              {this.state.startSale ? (
                <p className="sale-text">
                  4th of July Sale is live now! Up to{" "}
                  <span style={{ color: "#59fdd7", fontWeight: "500" }}>
                    40% off
                  </span>{" "}
                  on all PrepAI Premium Plans
                </p>
              ) : (
                <p style={{ left: "inherit" }} className="sale-text">
                  Get ready for the Special Easter Sale.{" "}
                  <span style={{ color: "pink", fontWeight: "500" }}>
                    Coming Soon!
                  </span>
                </p>
              )}
            </div>
            {this.state.timeLeft.seconds != undefined &&
              this.state.startSale && (
                <div className="time-frame">
                  <div className="ends-in">
                    <span style={{ color: "#59fdd7", fontWeight: "500" }}>
                      Ends in
                    </span>
                    <div className="startin">
                      <div className="d-flex flex-column wt-25">
                        <span className="ft-14-fw-500">
                          {this.state.timeLeft.days}
                        </span>
                        <span className="ft-11 text-white">DAYS</span>
                      </div>
                      <div className="d-flex flex-column wt-25">
                        <span className="ft-14-fw-500">
                          {this.state.timeLeft.hours}
                        </span>
                        <span className="ft-11 text-white">HOURS</span>
                      </div>
                      <div className="d-flex flex-column wt-25">
                        <span className="ft-14-fw-500">
                          {this.state.timeLeft.minutes}
                        </span>
                        <span className="ft-11 text-white">MINUTES</span>
                      </div>
                      <div className="d-flex flex-column wt-25">
                        <span className="ft-14-fw-500">
                          {this.state.timeLeft.seconds}
                        </span>
                        <span className="ft-11 text-white">SECONDS</span>
                      </div>
                    </div>
                  </div>
                    <div className="">
                      <Link to="/plans" style={{ textDecoration: "none" }}>
                        <p className="grab_deal_now">{"Get the deal >>"}</p>
                      </Link>
                    </div>
                </div>
              )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Salebar;
