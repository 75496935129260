import mixpanel from "mixpanel-browser";
mixpanel.init("5c85bd2f6699ea6f662f30d8f78f82d5", { debug: true });

export function trackEvent(eventName, properties = {}) {
  mixpanel.track(eventName, properties);
  return true;
}

export function timeEvent(eventName) {
  mixpanel.time_event(eventName);
  return true;
}

export function identifyUser(uniqueIdentifier) {
  mixpanel.identify(uniqueIdentifier);
  return true;
}

export function alias(userIdentifier) {
  mixpanel.alias(userIdentifier);
  return true;
}
