import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { addQuestion } from "../../services";
import { startLoader, stopLoader } from "../../store/reducers/generalActions";
import { notifyError, notifySuccess } from "../../utils/Helper";

class Fillups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newquestions: {
        question: [],
        options: ["", "", "", ""],
        category_type: 6,
        content_id: props.questions[0].content_id,
        topic: props.questions[0].topic,
      },
      titleofQuestion_emsg: "",
      option_emsg: "",
      selectedOption: null,
      optionIndex: null,
      isEmptyIndex: null,
      isOptionEmpty: null,
    };
  }

  validatemainquestion = () => {
    var que = this.state.newquestions.question;
    if (que.length == 0) {
      this.setState({
        isEmptyIndex: 0,
        titleofQuestion_emsg: "Please Enter Question.",
      });
      return false;
    }

    if (que.trim() == "") {
      this.setState({
        isEmptyIndex: 0,
        titleofQuestion_emsg: "Please Enter Question.",
      });
      return false;
    }

    this.setState({
      titleofQuestion_emsg: "",
    });
    return true;
  };

  validateoption = () => {
    var opt = this.state.newquestions.options;
    var option = opt.map((val) => val.replace("*", ""));

    if (option.length < 4) {
      this.setState({
        isOptionEmpty: Math.max(0, option.length - 1),
        option_emsg: "Please Enter the option",
      });
      return false;
    }

    for (var i = 0; i < option.length; i++) {
      if (option[i].trim() == "") {
        this.setState({
          isOptionEmpty: i,
          option_emsg: "Please Enter the option",
        });
        return false;
      }
    }

    if (this.state.optionIndex == null) {
      this.setState({
        option_emsg: "Please mark the correct answer.",
      });
      return false;
    }

    this.setState({
      option_emsg: "",
    });

    return true;
  };

  add = (event) => {
    this.setState({
      titleofQuestion_emsg: "",
      isEmptyIndex: null,
    });

    var newquestion = this.state.newquestions;
    newquestion.question = event.target.value;
    this.setState({
      newquestions: newquestion,
    });
  };
  option = (event) => {
    this.setState({
      isOptionEmpty: null,
      option_emsg: "",
    });

    var newquestionObj = this.state.newquestions;
    var index = event.target.name;

    var val = newquestionObj.options[index - 1];

    if (val.endsWith("*")) {
      newquestionObj.options[index - 1] = event.target.value + " *";
    } else {
      newquestionObj.options[index - 1] = event.target.value;
    }

    this.setState({
      newquestions: newquestionObj,
    });
  };

  radioButtonValue = (event) => {
    this.setState({
      optionIndex: event.target.name,
      selectedOption: event.target.value,
    });
  };

  handlesubmit = () => {
    // this.props.handlestate(this.state.newquestions);
    if (!this.validatemainquestion() || !this.validateoption()) {
      return false;
    }
    var reqObj = _.cloneDeep(this.state);
    var que = _.cloneDeep(this.state.newquestions.question);
    var changedQue = _.cloneDeep(this.state.newquestions);
    changedQue.question = ["Ques  : " + que];
    reqObj.newquestions = changedQue;

    if (this.state.optionIndex != null) {
      var optionObj = reqObj.newquestions.options;
      var opt = optionObj.map((val) => val.replace("*", ""));
      opt[this.state.optionIndex - 1] = opt[this.state.optionIndex - 1] + " *";
      reqObj.newquestions.options = opt;
    }
    this.doSubmit(reqObj);
  };

  doSubmit = async (req) => {
    this.props.dispatch(startLoader());
    try {
      const { data: res } = await addQuestion(req);
      if (res.success) {
        notifySuccess(res.message);
        const allQuestions = res.response;
        this.props.parentCallback(allQuestions);
        this.discard();
      } else {
        notifyError(res.message);
      }
      this.props.dispatch(stopLoader());
    } catch (err) {
      console.log(err);
    }
  };

  discard = () => {
    this.props.discardquestion("fillups");
  };

  render() {
    return (
      <React.Fragment>
        <div className="each-question-wrapper-box">
          <div className="quetsion--edit">
            <div className="quetsion--edit--markquee">Q.</div>
            <input
              className={
                this.state.isEmptyIndex == 1
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="1"
              value={this.state.newquestions.question}
              onChange={this.add}
              placeholder="Write your Question here..."
            />
          </div>

          {this.state.titleofQuestion_emsg && (
            <span className="text-danger" style={{ marginLeft: "3em" }}>
              {this.state.titleofQuestion_emsg}
            </span>
          )}

          <div className="editable--questions">
            <div className="quetsion--edit--markquee">
              <label className="mordern-radiobox-label my-0">
                <input
                  type="radio"
                  name="1"
                  onChange={(e) => this.radioButtonValue(e)}
                  checked={this.state.optionIndex == 1 ? true : false}
                />
                <span className="design"></span>
              </label>
            </div>

            <input
              className={
                this.state.isOptionEmpty == 0
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="1"
              value={this.state.newquestions.options[0]}
              onChange={this.option}
              placeholder="Option 1"
            />
          </div>
          <div className="editable--questions">
            <div className="quetsion--edit--markquee">
              <label className="mordern-radiobox-label my-0">
                <input
                  type="radio"
                  name="2"
                  onChange={(e) => this.radioButtonValue(e)}
                  checked={this.state.optionIndex == 2 ? true : false}
                />
                <span className="design"></span>
              </label>
            </div>
            <input
              className={
                this.state.isOptionEmpty == 1
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="2"
              value={this.state.newquestions.options[1]}
              onChange={this.option}
              placeholder="Option 2"
            />
          </div>
          <div className="editable--questions">
            <div className="quetsion--edit--markquee">
              <label className="mordern-radiobox-label my-0">
                <input
                  type="radio"
                  name="3"
                  onChange={(e) => this.radioButtonValue(e)}
                  checked={this.state.optionIndex == 3 ? true : false}
                />
                <span className="design"></span>
              </label>
            </div>
            <input
              className={
                this.state.isOptionEmpty == 2
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="3"
              value={this.state.newquestions.options[2]}
              onChange={this.option}
              placeholder="Option 3"
            />
          </div>
          <div className="editable--questions">
            <div className="quetsion--edit--markquee">
              <label className="mordern-radiobox-label my-0">
                <input
                  type="radio"
                  name="4"
                  onChange={(e) => this.radioButtonValue(e)}
                  checked={this.state.optionIndex == 4 ? true : false}
                />
                <span className="design"></span>
              </label>
            </div>
            <input
              className={
                this.state.isOptionEmpty == 3
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="4"
              value={this.state.newquestions.options[3]}
              onChange={this.option}
              placeholder="Option 4"
            />
          </div>

          {this.state.option_emsg && (
            <span className="text-danger" style={{ marginLeft: "3em" }}>
              {this.state.option_emsg}
            </span>
          )}

          <div class="editable-question-submitbox">
            <button
              type="button"
              role="button"
              className="preview--paper--btn font--400 font--14 w-110"
              onClick={this.handlesubmit}
            >
              Add
            </button>
            <button
              role="button"
              type="putton"
              className="preview--button font--400 font--14 w-110 ml-2"
              onClick={this.discard}
            >
              Discard
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
};
export default connect(mapStateToProps)(Fillups);
