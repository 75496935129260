export const MATHS_TOPIC = [
  { value: 1, label: "Algebra" },
  { value: 2, label: "Geometry" },
  { value: 3, label: "Trigonometry" },
  { value: 4, label: "Calculus" },
  { value: 5, label: "Differential equations" },
  { value: 6, label: "Linear algebra" },
  { value: 7, label: "Number theory" },
  { value: 8, label: "Statistics" },
  { value: 9, label: "Probability theory" },
  { value: 10, label: "Combinatorics" },
  { value: 11, label: "Topology" },
  { value: 12, label: "Graph theory" },
  { value: 13, label: "Logic" },
  { value: 14, label: "Set theory" },
  { value: 15, label: "Number systems" },
  { value: 16, label: "Geometry of shapes" },
  { value: 17, label: "Algebraic structures" },
  { value: 18, label: "Number theory and operations" },
  { value: 19, label: "Analysis of algorithms" },
  { value: 20, label: "Data structures" },
  { value: 21, label: "Programming paradigms" },
  { value: 22, label: "Database systems" },
  { value: 23, label: "Computer architecture" },
  { value: 24, label: "Computer networks" },
  { value: 25, label: "Operating systems" },
  { value: 26, label: "Theory of computation" },
  { value: 27, label: "Artificial intelligence" },
  { value: 28, label: "Computer graphics" },
  { value: 29, label: "Human-computer interaction" },
  { value: 30, label: "Software engineering" },
  { value: 31, label: "Programming languages" },
  { value: 32, label: "Web development" },
  { value: 33, label: "Mobile development" },
  { value: 34, label: "Game development" },
  { value: 35, label: "Data science" },
  { value: 36, label: "Machine learning" },
  { value: 37, label: "Computer vision" },
  { value: 38, label: "Natural language processing" },
  { value: 39, label: "Robotics" },
  { value: 40, label: "Control systems" },
  { value: 41, label: "Implicit differentiation" },
  { value: 42, label: "Related rates" },
  { value: 43, label: "Applications of derivatives" },
  { value: 44, label: "Mean value theorem" },
  { value: 45, label: "Riemann sums" },
  { value: 46, label: "Integrals" },
  { value: 47, label: "Integration by substitution" },
  { value: 48, label: "Integration by parts" },
  { value: 49, label: "Trigonometric substitutions" },
  { value: 50, label: "Partial fractions" },
  { value: 51, label: "Applications of integrals" },
  { value: 52, label: "Fundamental theorem of calculus" },
  { value: 53, label: "Polar coordinates" },
  { value: 54, label: "Parametric equations" },
  { value: 55, label: "Vector-valued functions" },
  { value: 56, label: "Three-dimensional geometry" },
  { value: 57, label: "Cylindrical coordinates" },
  { value: 58, label: "Spherical coordinates" },
  { value: 59, label: "Conic sections in polar coordinates" },
  { value: 60, label: "Partial derivatives" },
  { value: 61, label: "Gradient" },
  { value: 62, label: "Divergence" },
  { value: 63, label: "Curl" },
  { value: 64, label: "Laplacian" },
  { value: 65, label: "Taylor series" },
  { value: 66, label: "Power series" },
  { value: 67, label: "Fourier series" },
  { value: 68, label: "Differential equations" },
  { value: 69, label: "Separation of variables" },
];

export const CHEMISTRY_TOPIC = [
  { value: 1, label: "Acid dissociation constant" },
  { value: 2, label: "Nonhomogeneous equations" },
  { value: 3, label: "First-order equations" },
  { value: 4, label: "Higher-order equations" },
  { value: 5, label: "Series solutions" },
  { value: 6, label: "Laplace transforms" },
  { value: 7, label: "Atomic structure" },
  { value: 8, label: "Periodic table and periodicity" },
  { value: 9, label: "Chemical bonding" },
  { value: 10, label: "Chemical reactions" },
  { value: 11, label: "Stoichiometry" },
  { value: 12, label: "Gas laws" },
  { value: 13, label: "Solutions and solubility" },
  { value: 14, label: "Acids and bases" },
  { value: 15, label: "Redox reactions" },
  { value: 16, label: "Thermochemistry" },
  { value: 17, label: "Electrochemistry" },
  { value: 18, label: "Organic chemistry" },
  { value: 19, label: "Alkanes, alkenes, and alkynes" },
  { value: 20, label: "Functional groups" },
  { value: 21, label: "Isomerism" },
  { value: 22, label: "Alcohols and ethers" },
  { value: 23, label: "Carboxylic acids and esters" },
  { value: 24, label: "Amines and amides" },
  { value: 25, label: "Polymers" },
  { value: 26, label: "Biochemistry" },
  { value: 27, label: "Proteins and enzymes" },
  { value: 28, label: "Carbohydrates" },
  { value: 29, label: "Lipids" },
  { value: 30, label: "Nucleic acids" },
  { value: 31, label: "Chemical kinetics" },
  { value: 32, label: "Equilibrium" },
  { value: 33, label: "Le Chatelier's principle" },
  { value: 34, label: "Acid-base equilibria" },
  { value: 35, label: "Solubility equilibria" },
  { value: 36, label: "Thermodynamics" },
  { value: 37, label: "Laws of thermodynamics" },
  { value: 38, label: "Entropy and free energy" },
  { value: 39, label: "Hess's law" },
  { value: 40, label: "Calorimetry" },
  { value: 41, label: "Kinetic theory of gases" },
  { value: 42, label: "Ideal gas law" },
  { value: 43, label: "Van der Waals equation" },
  { value: 44, label: "Colligative properties" },
  { value: 45, label: "Raoult's law" },
  { value: 46, label: "Henry's law" },
  { value: 47, label: "Boyle's law" },
  { value: 48, label: "Charles's law" },
  { value: 49, label: "Dalton's law of partial pressures" },
  { value: 50, label: "Combined gas law" },
  { value: 51, label: "Dalton's atomic theory" },
  { value: 52, label: "Law of definite proportions" },
  { value: 53, label: "Law of multiple proportions" },
  { value: 54, label: "Mole concept" },
  { value: 55, label: "Avogadro's law" },
  { value: 56, label: "Empirical and molecular formulas" },
  { value: 57, label: "Balancing chemical equations" },
  { value: 58, label: "Stoichiometric calculations" },
  { value: 59, label: "Limiting reactants" },
  { value: 60, label: "Percent yield" },
  { value: 61, label: "Solutions and concentration units" },
  { value: 62, label: "Molarity" },
  { value: 63, label: "Molality" },
  { value: 64, label: "Parts per million and parts per billion" },
  { value: 65, label: "Solubility product constant" },
  { value: 66, label: "Homogeneous equations " },
  { value: 67, label: "Base dissociation constant" },
  { value: 68, label: "Henderson-Hasselbalch equation" },
  { value: 69, label: "Titration curves" },
  { value: 70, label: "Buffers" },
  { value: 71, label: "Oxidation states" },
  { value: 72, label: "Balancing redox equations" },
  { value: 73, label: "Electrochemical cells" },
  { value: 74, label: "Nernst equation" },
  { value: 75, label: "Faraday's laws of electrolysis" },
  { value: 76, label: "Organic functional groups" },
  { value: 77, label: "Alkanes, alkenes, and alkynes reactions" },
  { value: 78, label: "SN1 and SN2 reactions" },
  { value: 79, label: "E1 and E2 reactions" },
  { value: 80, label: "Fischer projections" },
  { value: 81, label: "Organic spectroscopy" },
  { value: 82, label: "Chemistry basics" },
  { value: 83, label: "Chemical reactions and stoichiometry" },
  { value: 84, label: "Acid-base chemistry" },
  { value: 85, label: "Solutions and solubility" },
  { value: 86, label: "Chemical equilibrium" },
  { value: 87, label: "Thermochemistry" },
  { value: 88, label: "Electrochemistry" },
  { value: 89, label: "Organic chemistry" },
  { value: 90, label: "Wave-particle duality" },
  { value: 91, label: "Photoelectric effect" },
  { value: 92, label: "Spectroscopy and energy levels" },
  { value: 93, label: "Uncertainty principle" },
  { value: 94, label: "Radioactivity and nuclear reactions" },
  { value: 95, label: "Doppler effect" },
  { value: 96, label: "Special relativity" },
  { value: 97, label: "General relativity" },
  { value: 98, label: "Black holes" },
  { value: 99, label: "Dark matter and dark energy" },
  { value: 100, label: "Big Bang theory" },
  { value: 101, label: "Cosmology" },
  { value: 102, label: "Astronomy" },
  { value: 103, label: "Telescopes and observatories" },
  { value: 104, label: "Planetary science" },
  { value: 105, label: "Geology and Earth science" },
  { value: 106, label: "Meteorology and weather" },
  { value: 107, label: "Oceanography and marine science" },
  { value: 108, label: "Environmental science" },
];

export const PHYSICS_TOPIC = [
  { value: 1, label: "Archimedes' principle" },
  { value: 2, label: "Newton's laws of motion" },
  { value: 3, label: "Forces and friction" },
  { value: 4, label: "Work, energy, and power" },
  { value: 5, label: "Conservation of energy" },
  { value: 6, label: "Linear momentum" },
  { value: 7, label: "Circular motion" },
  { value: 8, label: "Gravity and orbits" },
  { value: 9, label: "Projectile motion" },
  { value: 10, label: "Torque and rotational motion" },
  { value: 11, label: "Simple harmonic motion" },
  { value: 12, label: "Waves and wave properties" },
  { value: 13, label: "Sound and sound waves" },
  { value: 14, label: "Light and optics" },
  { value: 15, label: "Reflection and refraction" },
  { value: 16, label: "Mirrors and lenses" },
  { value: 17, label: "Electromagnetic waves" },
  { value: 18, label: "Electric charge and electric field" },
  { value: 19, label: "Coulomb's law" },
  { value: 20, label: "Electric potential and electric potential energy" },
  { value: 21, label: "Capacitance and capacitors" },
  { value: 22, label: "Electric current and circuits" },
  { value: 23, label: "Ohm's law" },
  { value: 24, label: "Resistors and resistivity" },
  { value: 25, label: "Kirchhoff's laws" },
  { value: 26, label: "Magnetic fields and forces" },
  { value: 27, label: "Electromagnetic induction" },
  { value: 28, label: "Transformers and generators" },
  { value: 29, label: "Atomic structure" },
  { value: 30, label: "Radioactivity and nuclear reactions" },
  { value: 31, label: "Quantum mechanics" },
  { value: 32, label: "Particle physics" },
  { value: 33, label: "Heat and temperature" },
  { value: 34, label: "Thermal energy and heat transfer" },
  { value: 35, label: "Specific heat and calorimetry" },
  { value: 36, label: "Gas laws" },
  { value: 37, label: "Ideal gas law" },
  { value: 38, label: "Kinetic theory of gases" },
  { value: 39, label: "Thermodynamics" },
  { value: 40, label: "Laws of thermodynamics" },
  { value: 41, label: "Entropy and free energy" },
  { value: 42, label: "Phase changes and phase diagrams" },
  { value: 43, label: "Properties of solids, liquids, and gases" },
  { value: 44, label: "Density and buoyancy" },
  { value: 45, label: "Pressure and Pascal's principle" },
  { value: 46, label: "Fluids and fluid mechanics" },
  { value: 47, label: "Kinematics" },
  { value: 48, label: "Viscosity and Bernoulli's principle" },
];

export const APTITUDE_TOPIC = [
  { value: 1, label: "Abductive reasoning" },
  { value: 2, label: "Coding and Number Series" },
  { value: 3, label: "Letter and Symbol Series" },
  { value: 4, label: "Logical Sequence of Words" },
  { value: 5, label: "Analogy and Classification Pattern" },
  { value: 6, label: "Cube and Cuboid" },
  { value: 7, label: "Cube" },
  { value: 8, label: "Dice" },
  { value: 9, label: "Classic Reasoning" },
  { value: 10, label: "Directional Senses" },
  { value: 11, label: "Blood Relations" },
  { value: 12, label: "Odd Man Out" },
  { value: 13, label: "Syllogism" },
  { value: 14, label: "Arrangements" },
  { value: 15, label: "Seating Arrangements" },
  { value: 16, label: "Coding Deductive Logic" },
  { value: 17, label: "Objective Reasoning" },
  { value: 18, label: "Selection Decision Tables" },
  { value: 19, label: "Attention to Details" },
  { value: 20, label: "Inferred Meaning" },
  { value: 21, label: "Cryptarithmetic" },
  { value: 22, label: "Deductive reasoning" },
  { value: 23, label: "Inductive reasoning" },
  { value: 24, label: "Number Series" },
  { value: 25, label: "Analogical reasoning" },
  { value: 26, label: "Syllogisms" },
  { value: 27, label: "Conditional reasoning" },
  { value: 28, label: "Propositional logic" },
  { value: 29, label: "Predicate logic" },
  { value: 30, label: "Truth tables" },
  { value: 31, label: "Logical fallacies" },
  { value: 32, label: "Venn diagrams" },
  { value: 33, label: "Categorical syllogisms" },
  { value: 34, label: "Quantifiers" },
  { value: 35, label: "Argument analysis" },
  { value: 36, label: "Inference making" },
  { value: 37, label: "Decision making" },
  { value: 38, label: "Game theory" },
  { value: 39, label: "Modal logic" },
  { value: 40, label: "Counterfactual reasoning" },
  { value: 41, label: "Non-monotonic reasoning" },
  { value: 42, label: "Fuzzy logic" },
  { value: 43, label: "Intuitionistic logic" },
  { value: 44, label: "Epistemic logic" },
  { value: 45, label: "Deontic logic" },
  { value: 46, label: "Temporal logic" },
];

// Formulas
export const PHYSICS_FORMULA = [
  { value: 0, label: "AC Circuits: V = Vm sin(ωt + φ)" },
  { value: 1, label: "Kinetic Energy: KE = 0.5mv^2" },
  { value: 2, label: "Gravitational Potential Energy: PE = mgh" },
  { value: 3, label: "Work-Energy Theorem: W = ∆KE = Fd" },
  { value: 4, label: "Power: P = W/t" },
  {
    value: 5,
    label: "Law of Conservation of Energy: KEi + PEi + Wi = KEf + PEf + Wf",
  },
  { value: 6, label: "Momentum: p = mv" },
  { value: 7, label: "Impulse: J = ∆p = F∆t" },
  { value: 8, label: "Law of Conservation of Momentum: pi = pf" },
  { value: 9, label: "Circular Motion: Fc = mv^2/r" },
  { value: 10, label: "Centripetal Acceleration: ac = v^2/r" },
  { value: 11, label: "Torque: τ = Frsinθ" },
  { value: 12, label: "Angular Momentum: L = Iω" },
  { value: 13, label: "Work done by a Torque: W = τθ" },
  { value: 14, label: "Simple Harmonic Motion: x = A cos(ωt + φ)" },
  { value: 15, label: "Periodic Time: T = 2π/ω" },
  { value: 16, label: "Hooke's Law: F = -kx" },
  { value: 17, label: "Density: ρ = m/V" },
  { value: 18, label: "Pressure: P = F/A" },
  { value: 19, label: "Pascal's Principle: P1 = P2" },
  { value: 20, label: "Archimedes' Principle: Fb = ρVg" },
  { value: 21, label: "Gas Laws: PV = nRT" },
  { value: 22, label: "Ideal Gas Law: PV = NkBT" },
  { value: 23, label: "Boyle's Law: P1V1 = P2V2" },
  { value: 24, label: "Charles' Law: V1/T1 = V2/T2" },
  { value: 25, label: "Avogadro's Law: V1/n1 = V2/n2" },
  { value: 26, label: "Specific Heat: Q = mc∆T" },
  { value: 27, label: "Latent Heat: Q = mL" },
  { value: 28, label: "First Law of Thermodynamics: ∆U = Q - W" },
  { value: 29, label: "Second Law of Thermodynamics: ∆S ≥ Q/T" },
  { value: 30, label: "Entropy: S = k ln(W)" },
  { value: 31, label: "Coulomb's Law: F = kq1q2/r^2" },
  { value: 32, label: "Electric Field: E = F/q" },
  { value: 33, label: "Electric Potential Energy: U = kq1q2/r" },
  { value: 34, label: "Electric Potential: V = U/q" },
  { value: 35, label: "Capacitance: C = Q/V" },
  { value: 36, label: "Electric Current: I = Q/t" },
  { value: 37, label: "Resistance: R = V/I" },
  { value: 38, label: "Ohm's Law: V = IR" },
  { value: 39, label: "Kirchhoff's Laws: ∑V = 0, ∑I = 0" },
  { value: 40, label: "Magnetic Field: B = F/qv" },
  { value: 41, label: "Magnetic Force: F = qvBsinθ" },
  { value: 42, label: "Magnetic Flux: Φ = BAcosθ" },
  { value: 43, label: "Faraday's Law: ε = -dΦ/dt" },
  { value: 44, label: "Inductance: L = Φ/I" },
  { value: 45, label: "Newton's Second Law of Motion: F = ma" },
  { value: 46, label: "Resonance Frequency: f = 1/2π√(LC)" },
  { value: 47, label: "Reflection: θi = θr" },
  { value: 48, label: "Refraction: n1sinθ1 = n2sinθ2" },
  { value: 49, label: "Diffraction: λ = d sinθ" },
];

export const MATHS_FORMULA = [
  { value: 1, label: "Area of a Circle: A = πr²" },
  {
    value: 2,
    label: "Quadratic Formula: x = (-b ± √(b²-4ac))/2a",
  },
  { value: 3, label: "Distance Formula: d = √((x2 - x1)² + (y2 - y1)²)" },
  { value: 4, label: "Slope of a Line: m = (y2 - y1)/(x2 - x1)" },
  {
    value: 5,
    label: "Midpoint Formula: ((x1 + x2)/2 , (y1 + y2)/2)",
  },
  { value: 6, label: "Area of a Triangle: A = 1/2bh" },
  { value: 7, label: "Volume of a Sphere: V = 4/3πr³" },
  { value: 8, label: "Pythagorean Theorem: a² + b² = c²" },
  {
    value: 9,
    label: "Surface Area of a Sphere: SA = 4πr²",
  },
  { value: 10, label: "Sin Rule: a/sin(A) = b/sin(B) = c/sin(C)" },
  { value: 11, label: "Cos Rule: a² = b² + c² - 2bc cos(A)" },
  {
    value: 12,
    label:
      "Logarithmic identities: log(ab) = log(a) + log(b), log(a/b) = log(a) - log(b), log(a^n) = nlog(a)",
  },
  {
    value: 13,
    label:
      "Probability of an event: P(E) = number of favorable outcomes/total number of outcomes",
  },
  { value: 14, label: "Perimeter of a rectangle: P = 2(l + w)" },
  { value: 15, label: "Area of a rectangle: A = lw" },
  { value: 16, label: "Volume of a rectangular prism: V = lwh" },
  {
    value: 17,
    label: "Surface Area of a rectangular prism: SA = 2lw + 2lh + 2wh",
  },
  {
    value: 18,
    label: "Arithmetic sequence: an = a1 + (n-1)d",
  },
  {
    value: 19,
    label: "Geometric sequence: an = a1(r^(n-1))",
  },
  { value: 20, label: "Factorial: n! = n(n-1)(n-2)...(3)(2)(1)" },
  {
    value: 21,
    label: "Combinations: nCk = n!/[(n-k)!k!]",
  },
  {
    value: 22,
    label: "Binomial Theorem: (a + b)^n = Σ (n choose k) a^(n-k) b^k",
  },
  { value: 23, label: "Law of Sines: sin(A)/a = sin(B)/b = sin(C)/c" },
  {
    value: 24,
    label: "Law of Cosines: a² = b² + c² - 2bc cos(A)",
  },
  { value: 25, label: "Quadratic Regression Formula: y = a + bx + cx²" },
  {
    value: 26,
    label:
      "Trigonometric Identities: sin²(x) + cos²(x) = 1, tan(x) = sin(x)/cos(x), sin(-x) = -sin(x), cos(-x) = cos(x)",
  },
  { value: 27, label: "Sum of an infinite geometric series: S = a/(1 - r)" },
  { value: 28, label: "Sum of a finite arithmetic series: S = (n/2)(a1 + an)" },
  {
    value: 29,
    label: "Sum of a finite geometric series: S = (a1(1 - r^n))/(1 - r)",
  },
  {
    value: 30,
    label: "Logarithmic differentiation: d/dx(log(f(x))) = f'(x)/f(x)",
  },
  {
    value: 31,
    label: "Integration by substitution: ∫f(g(x))g'(x)dx = ∫f(u)du",
  },
  { value: 32, label: "Integration by parts: ∫u dv = uv - ∫v du" },
  {
    value: 33,
    label:
      "Limits: lim(x → a) f(x) = L if and only if for every ε > 0, there exists δ > 0 such that if 0 < |x - a| < δ, then |f(x) - L| < ε",
  },
  {
    value: 34,
    label: "Derivative of a function: f'(x) = lim(h → 0) (f(x + h) - f(x))/h",
  },
];

export const CHEMISTRY_FORMULA = [
  { value: 1, label: "Acid dissociation constant: Ka = [H3O+][A-]/[HA]" },
  { value: 2, label: "Molar mass: M = m/n" },
  { value: 3, label: "Ideal gas law: PV = nRT" },
  { value: 4, label: "Mole fraction: X = n1/n(total)" },
  {
    value: 5,
    label:
      "Mass percent composition: % composition = (mass of element/mass of compound) x 100%",
  },
  { value: 6, label: "Molarity: M = n/V" },
  { value: 7, label: "Molality: m = n/mass of solvent (in kg)" },
  { value: 8, label: "Boiling point elevation: ΔTb = Kb x m" },
  { value: 9, label: "Freezing point depression: ΔTf = Kf x m" },
  { value: 10, label: "Raoult's law: Psolution = Xsolvent x Psolvent" },
  { value: 11, label: "Henry's law: Pgas = Kh x concentration of gas" },
  { value: 12, label: "Ideal solution: ΔHmix = 0" },
  { value: 13, label: "Non-ideal solution: ΔHmix ≠ 0" },
  { value: 14, label: "Reaction rate: rate = k[A]^m[B]^n" },
  { value: 15, label: "Equilibrium constant: Kc = [C]^c[D]^d/[A]^a[B]^b" },
  { value: 16, label: "Gibbs free energy: ΔG = ΔH - TΔS" },
  { value: 17, label: "Standard free energy change: ΔG° = -RT lnK" },
  {
    value: 18,
    label:
      "Le Chatelier's principle: a system at equilibrium will respond to a stress in a way that opposes the stress",
  },
  { value: 19, label: "Avogadro's number: NA = 6.022 x 10^23 mol^-1" },
  { value: 20, label: "Base dissociation constant: Kb = [BH+][OH-]/[B]" },
  { value: 21, label: "pH: pH = -log[H3O+]" },
  { value: 22, label: "pOH: pOH = -log[OH-]" },
  { value: 23, label: "pKa: pKa = -log(Ka)" },
  { value: 24, label: "pKb: pKb = -log(Kb)" },
  {
    value: 25,
    label: "Buffer capacity: β = (moles of acid or base added)/(change in pH)",
  },
  {
    value: 26,
    label: "Henderson-Hasselbalch equation: pH = pKa + log([A-]/[HA])",
  },
  { value: 27, label: "Solubility product constant: Ksp = [A+]^m[B-]^n" },
  {
    value: 28,
    label:
      "Common ion effect: the solubility of a salt is decreased by the presence of a common ion",
  },
  {
    value: 29,
    label:
      "Acid-base indicators: substances that change color depending on the pH of the solution",
  },
  {
    value: 30,
    label:
      "Oxidation number: the charge an atom would have if electrons were transferred completely",
  },
  {
    value: 31,
    label:
      "Oxidation-reduction reaction: a reaction in which electrons are transferred from one species to another",
  },
  {
    value: 32,
    label:
      "Balancing redox reactions: use half-reactions and balance the number of electrons transferred",
  },
  { value: 33, label: "Faraday's law: ΔEcell = Ecell cathode - Ecell anode" },
  { value: 34, label: "Nernst equation: Ecell = E°cell - (RT/nF) lnQ" },
  { value: 35, label: "Standard reduction potential: E°red = -ΔG°/nF" },
  {
    value: 36,
    label:
      "Acid-base titration: a method of determining the concentration of an acid or base using a known concentration of the opposite species",
  },
  {
    value: 37,
    label:
      "Gravimetric analysis: a method of determining the amount of a substance by measuring its mass",
  },
  {
    value: 38,
    label:
      "Spectroscopy: a method of identifying substances by their interaction with electromagnetic radiation",
  },
  {
    value: 39,
    label:
      "Infrared spectroscopy: a method of identifying functional groups in organic molecules",
  },
  {
    value: 40,
    label:
      "Nuclear magnetic resonance spectroscopy: a method of determining the structure of organic molecules",
  },
  {
    value: 41,
    label:
      "Chromatography: a method of separating mixtures based on differences in solubility and/or polarity",
  },
  {
    value: 42,
    label:
      "Distillation: a method of separating mixtures based on differences in boiling",
  },
];
