
import React, { useState } from "react";
  
const ReadMore = ({ children }) => {
  const text = children;

  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  if (!text) {
    return <p>No Explanation Found!</p>
  }
  return (
    <p style={{wordBreak: "break-word"}} className="">
      <span style={{fontWeight:"bold", textDecoration: "underline"}}>Explanation</span>{" "} - {" "}{isReadMore ? text.slice(0, 150) : text}
      <span onClick={toggleReadMore} style={{cursor: "pointer", color:"#1f1fe9", fontStyle: "italic", textDecoration: "underline"}}>
        {text.length > 150 && (isReadMore ? "...read more" : " -show less")}
      </span>
    </p>
  );
};

export default ReadMore