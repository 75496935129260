import { Component } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
  Button,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import {
  getFeatureRequests,
  addFeatureRequest,
  getVotes,
} from "../../services";
import { connect } from "react-redux";
import { startLoader, stopLoader } from "../../store/reducers/generalActions";
import { notifyError, notifySuccess } from "../../utils/Helper";
import Feature from "./Feature";

class FeatureRequest extends Component {
  state = {
    expanded: false,
    featureRequests: [],
    featureTitle: "",
    featureDescription: "",
    titleError: false,
    liked: false,
    disliked: false,
  };

  handleAccordion = (panel) => (e, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  requestFeature = async (title, description) => {
    if (title.length < 5) {
      this.setState({ titleError: true });
      return;
    }

    this.props.dispatch(startLoader());

    try {
      const featureReq = {
        title,
        description,
        feature_status: 0,
        likes: 0,
        dislikes: 0,
      };

      const req = {
        title,
        description,
      };

      const { data } = await addFeatureRequest(req);

      if (data.success) {
        this.setState({
          featureRequests: [
            { id: data.response.id, ...featureReq },
            ...this.state.featureRequests,
          ],
          featureTitle: "",
          featureDescription: "",
          expanded: !this.state.expanded,
        });
        this.state.featureRequests.length > 0
          ? notifySuccess(data.message)
          : notifyError("Could not request feature");
      }
    } catch (e) {
      console.error(e.message);
    }

    this.props.dispatch(stopLoader());
  };

  async componentDidMount() {
    this.props.dispatch(startLoader());
    try {
      const { data } = await getFeatureRequests();
      if (data.success && data.response) {
        this.setState({ featureRequests: data.response });
        this.state.featureRequests.length > 0 && notifySuccess(data.message);
      }
    } catch (error) {
      console.error(error);
      notifyError(error.message);
    }
    this.props.dispatch(stopLoader());
  }

  render() {
    return (
      <div style={{ marginBottom: "2rem" }}>
        <Accordion
          style={{ padding: "0.1rem", margin: "10px" }}
          expanded={this.state.expanded === "suggestPanel"}
          onChange={this.handleAccordion("suggestPanel")}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="suggestPanel-content"
            id="suggestPanel-header"
          >
            <h6> Suggest a new feature </h6>
          </AccordionSummary>
          <AccordionDetails>
            <div className="feature-requests">
              <TextField
                id="f-title"
                label="Title"
                variant="standard"
                error={this.state.titleError}
                helperText={
                  this.state.titleError &&
                  "Title should be of minimum 5 characters"
                }
                style={{ width: "100%" }}
                value={this.state.featureTitle}
                onChange={(e) =>
                  this.setState({
                    featureTitle: e.target.value,
                    titleError: this.state.featureTitle.length > 5 && false,
                  })
                }
              />

              <TextField
                id="f-details"
                label="Details"
                multiline
                rows={4}
                variant="standard"
                style={{ width: "100%", marginTop: "15px" }}
                value={this.state.featureDescription}
                onChange={(e) =>
                  this.setState({ featureDescription: e.target.value })
                }
              />

              <Button
                variant="contained"
                className="request__btn"
                // disabled = {this.state.featureTitle.length < 5}
                onClick={() =>
                  this.requestFeature(
                    this.state.featureTitle,
                    this.state.featureDescription
                  )
                }
              >
                {" "}
                Request Feature{" "}
              </Button>
            </div>
          </AccordionDetails>
        </Accordion>

        {this.state.featureRequests.map(
          ({ id, title, description, feature_status, likes, dislikes }) => {
            return (
              <>
                <Feature
                  key={id}
                  id={id}
                  title={title}
                  description={description}
                  feature_status={feature_status}
                  likes={likes}
                  dislikes={dislikes}
                />
                <hr style={{ marginTop: "0" }} />
              </>
            );
          }
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(FeatureRequest);
