import React, { Component } from "react";
import { TextField, Tooltip } from "@mui/material";
import SweetAlert from "react-bootstrap-sweetalert";
import TimeField from "react-simple-timefield";
import { createAssessment } from "../../services";
import { startLoader } from "../../store/reducers/generalActions";
import MSG, { notifyError, notifySuccess } from "../../utils/Helper";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class TakeAssessment extends Component {
  state = {
    show: false,
    currentContentId: "",
    currentTopicName: "",
    time: "00:00",
    timerSeconds: 0,
    showTimeError: false,
    showTopicError: false,
  };

  componentWillReceiveProps(nextProps) {

    this.setState({
        show: nextProps.show,
      currentContentId: nextProps.content_id,
      currentTopicName: nextProps.topic_name,
    });
  }

  handleTopicChange = (event) => {
    const value = event.target.value;
    this.setState({ currentTopicName: value, showTopicError: false });
  };

  //SWEET ALERT
  onAlertConfirm = () => {
    if(!this.state.currentTopicName.trim()) {
        this.setState({showTopicError: true})
    } else if (this.state.timerSeconds < 1) {
        this.setState({showTimeError: true})
    } else {
        this.props.callBack(this.state.currentTopicName, false)
        this.setState({ show: false }, () => this.handleRetake(true));
    }
  };

  onAlertCancel = () => {
    this.props.callBack(this.state.currentTopicName, false)
    this.setState({ show: false });
  };

  onAlertSkip = () => {
    if(!this.state.currentTopicName.trim()) {
        this.setState({showTopicError: true})
    } else {
        this.props.callBack(this.state.currentTopicName, false)
        this.setState({ show: false }, () => this.handleRetake(false));
    }
  };

  assessmentTimerHandler = (event, value) => {
    const newTime = value.replace(/-/g, ":");
    const time = newTime.substr(0, 5);
    const seconds = time.split(":");
    const timerSeconds =
      Number(seconds[0]) * 60 * 60 +
      Number(seconds[1]) * 60
    this.setState({ time, timerSeconds, showTimeError: false });
  };

  handleRetake = async (isTimer) => {
    this.props.dispatch(startLoader());
    const content_id = this.state.currentContentId;
    try {
      const req = {
        content_id: content_id,
        duration: isTimer ? this.state.timerSeconds: 0,
        topic: this.state.currentTopicName,
      };

      const { data: res } = await createAssessment(req);
      if (res.success == true) {
        notifySuccess(res.message);
        console.log(content_id)
        this.props.history.push({
          pathname: "/self-assessment",
          state: {
            content_id: content_id,
            test_id: res.response.test_id,
          },
        });
      } else {
        notifyError(res.message);
        this.props.dispatch(startLoader());
      }
    } catch (err) {
      notifyError(MSG.commonApiError);
      this.props.dispatch(startLoader());
    }
  };
  render() {
    return (
      <SweetAlert
        customClass="sweet-alert-assessment"
        show={this.state.show}
        title={
          <div className="swal-title">
            <h2 style={{ marginBottom: "-8px" }}>
              Start your self-assessment.
            </h2>
            <span
              style={{ fontSize: "14px", color: "grey", fontWeight: "400" }}
            >
              Set a time limit and finish your test within the entered time
            </span>
            <hr />
            <div className="position-relative mb-4 d-flex flex-column justify-content-center">
              <input
                className="subset-topic w-13 h1-8"
                type="text"
                name="topic"
                value={this.state.currentTopicName}
                onChange={this.handleTopicChange}
                id="titleofTest"
                aria-describedby="textHelp"
                placeholder="Enter Topic"
              />
              {this.state.showTopicError && <span style={{color: "red", fontSize: "14px", marginTop: "5px"}}>Please enter a topic name!</span>}
            </div>
            <div className="d-flex flex-column justify-content-center">
              <TimeField
                value={this.state.time}
                onChange={this.assessmentTimerHandler}
                style={{ alignSelf:"center" }}
                input={
                  <TextField
                    label="HH:MM"
                    value={this.state.time}
                    variant="outlined"
                    className="format-time"
                  />
                }
              />
              {this.state.showTimeError && <span style={{color: "red", fontSize: "14px", marginTop: "5px"}}>Please enter a time limit!</span>}
            </div>
          </div>
        }
        customButtons={
          <React.Fragment>
            <button
              className="btn sweet-alert-close-btn"
              onClick={this.onAlertCancel}
            >
              X
            </button>
            <div className="d-flex flex-column" style={{marginTop: "-0.5rem"}}>
              <button
                className="btn btn-success sweet-alert-confirm-btn  w-13 h1-8"
                onClick={this.onAlertConfirm}
              >
                Begin Self Assessment
              </button>
              <a
                href="#"
                className="link-primary"
                style={{ marginTop: "0.5rem" }}
                onClick={this.onAlertSkip}
              >
                Skip Time Limit
              </a>
            </div>
          </React.Fragment>
        }
        onEscapeKey={() => this.setState({ show: false }, () => this.props.callBack(this.state.currentTopicName, false))}
        onOutsideClick={() => this.setState({ show: false }, () => this.props.callBack(this.state.currentTopicName, false))}
      />
    );
  }
}

const mapStateToProps = (state) => {
    return {
      isAuthenticated: state.auth.isAuthenticated,
      user: state.auth.user,
    };
  };

export default connect(mapStateToProps)(withRouter(TakeAssessment));
