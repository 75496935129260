import React, { Component } from "react";
import { connect } from "react-redux";
import { startLoader, stopLoader } from "../../store/reducers/generalActions";
import { notifyError, notifySuccess } from "../../utils/Helper";
import { addQuestion } from "../../services";
import _ from "lodash";

class Addhardmcq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newquestions: {
        question: [],
        options: ["", "", "", ""],
        category_type: 3,
        content_id: props.questions[0].content_id,
        topic: props.questions[0].topic,
      },
      titleofQuestion_emsg: "",
      optionIndex: null,
      option_emsg: "",
      selectedOption: [],
      isChecked: [false, false, false, false],
      limit: 2,
      isEmptyIndex: null,
      isOptionEmpty: null,
      isRequired: "",
    };
  }

  validatemainquestion = () => {
    var que = this.state.newquestions.question;
    for (var i = 0; i < que.length; i++) {
      if (que[i].trim() == "") {
        this.setState({
          isEmptyIndex: i,
          titleofQuestion_emsg: "Please enter some text in the input field.",
        });
        return false;
      }
    }

    if (que.length < 5) {
      this.setState({
        isEmptyIndex: que.length,
        titleofQuestion_emsg: "Please enter some text in the input field.",
      });
      return false;
    }

    this.setState({
      titleofQuestion_emsg: "",
    });
    return true;
  };

  validateoption = () => {
    var opt = this.state.newquestions.options;

    for (var i = 0; i < opt.length; i++) {
      if (opt[i].trim() == "") {
        this.setState({
          isOptionEmpty: i,
          option_emsg: "Please Enter the option.",
        });
        return false;
      }
    }

    if (opt.length < 4) {
      this.setState({
        isOptionEmpty: Math.max(0, opt.length - 1),
        option_emsg: "Please Enter the option.",
      });
      return false;
    }

    var checkedBoxes = this.state.isChecked.filter(
      (item) => item == true
    ).length;

    if (checkedBoxes == 0) {
      this.setState({
        option_emsg: "Please mark the correct answer.",
      });
      return false;
    }

    this.setState({
      option_emsg: "",
    });

    return true;
  };

  add = (event) => {
    this.setState({
      titleofQuestion_emsg: "",
      isEmptyIndex: null,
    });

    var newquestion = this.state.newquestions;
    var index = event.target.name;
    newquestion.question[index - 1] = event.target.value;
    this.setState({
      newquestions: newquestion,
    });
  };

  option = (event) => {
    this.setState({
      isOptionEmpty: null,
      option_emsg: "",
    });
    let index = event.target.name;
    let newquestion = this.state.newquestions;
    var options = [...newquestion.options];
    var val = options[index - 1];
    if (val.endsWith("*")) {
      options[index - 1] = event.target.value;
    } else {
      options[index - 1] = event.target.value;
    }
    newquestion.options = options;

    this.setState({
      newquestions: newquestion,
    });
  };

  handleCheckBox = (event) => {
    this.setState({
      isOptionEmpty: null,
      option_emsg: "",
    });
    var checkedBoxes = this.state.isChecked.filter(
      (item) => item == true
    ).length;

    var index = event.target.name;
    var isCheckedArray = this.state.isChecked;

    if (checkedBoxes < this.state.limit) {
      isCheckedArray[index - 1] = !isCheckedArray[index - 1];
      this.setState({
        isChecked: isCheckedArray,
      });
    } else {
      if (isCheckedArray[index - 1] == true) {
        isCheckedArray[index - 1] = !isCheckedArray[index - 1];
        this.setState({
          isChecked: isCheckedArray,
        });
      } else {
        this.setState({
          option_emsg: "You can only select two check boxes",
        });
      }
    }
  };

  doSubmit = async (req) => {
    this.props.dispatch(startLoader());

    try {
      const { data: res } = await addQuestion(req);
      if (res.success) {
        notifySuccess(res.message);
        const allQuestions = res.response;
        this.props.parentCallback(allQuestions);
        this.discard();
      } else {
        notifyError(res.message);
      }
      this.props.dispatch(stopLoader());
    } catch (err) {
      console.log(err);
    }
  };
  handlesubmit = (e) => {
    e.preventDefault();
    if (!this.validatemainquestion() || !this.validateoption()) {
      return false;
    }
    var reqObj = _.cloneDeep(this.state);

    reqObj.newquestions.question = [
      "Ques  : " + this.state.newquestions.question[0],
      this.state.newquestions.question[1],
      this.state.newquestions.question[2],
      this.state.newquestions.question[3],
      this.state.newquestions.question[4],
    ];

    // if (this.state.optionIndex != null) {
    //   opt[this.state.optionIndex - 1] = opt[this.state.optionIndex - 1] + " *";
    //   reqObj.newquestions.options = opt;
    // }
    var cnt = 0;
    var optionsObj = reqObj.newquestions.options;
    var opt = optionsObj.map((val) => val.replace("*", ""));
    for (var i = 0; i <= 4; i++) {
      if (this.state.isChecked[i]) {
        opt[i] = opt[i] + " *";
      }
    }
    reqObj.newquestions.options = opt;

    this.doSubmit(reqObj);
  };

  discard = () => {
    this.props.discardquestion("hardmcq");
  };
  render() {
    return (
      <React.Fragment>
        <div className="each-question-wrapper-box">
          <div className="quetsion--edit">
            <div className="quetsion--edit--markquee">Q.</div>
            <input
              className={
                this.state.isEmptyIndex == 0
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="1"
              value={this.state.newquestions.question[0]}
              onChange={this.add}
              placeholder="Write your Question here..."
            />
          </div>
          <div className="quetsion--edit">
            <div className="quetsion--edit--markquee"></div>
            <input
              className={
                this.state.isEmptyIndex == 1
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="2"
              value={this.state.newquestions.question[1]}
              onChange={this.add}
              placeholder="Statement I"
            />
          </div>
          <div className="quetsion--edit">
            <div className="quetsion--edit--markquee"></div>
            <input
              className={
                this.state.isEmptyIndex == 2
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="3"
              value={this.state.newquestions.question[2]}
              onChange={this.add}
              placeholder="Statement II"
            />
          </div>
          <div className="quetsion--edit">
            <div className="quetsion--edit--markquee"></div>
            <input
              className={
                this.state.isEmptyIndex == 3
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="4"
              value={this.state.newquestions.question[3]}
              onChange={this.add}
              placeholder="Statement III"
            />
          </div>
          <div className="quetsion--edit">
            <div className="quetsion--edit--markquee"></div>
            <input
              className={
                this.state.isEmptyIndex == 4
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="5"
              value={this.state.newquestions.question[4]}
              onChange={this.add}
              placeholder="write your question"
            />
          </div>

          {this.state.titleofQuestion_emsg && (
            <span className="text-danger" style={{ marginLeft: "3em" }}>
              {this.state.titleofQuestion_emsg}
            </span>
          )}

          <div className="editable--questions">
            <div className="quetsion--edit--markquee">
              <label>
                <input
                  type="checkbox"
                  name="1"
                  checked={this.state.isChecked[0]}
                  onChange={(e) => this.handleCheckBox(e)}
                />
                <span className="design"></span>
              </label>
            </div>
            <input
              className={
                this.state.isOptionEmpty == 0
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="1"
              value={this.state.newquestions.options[0]}
              onChange={this.option}
              placeholder="Option 1"
            />
          </div>
          <div className="editable--questions">
            <div className="quetsion--edit--markquee">
              <label>
                <input
                  type="checkbox"
                  name="2"
                  onChange={(e) => this.handleCheckBox(e)}
                  checked={this.state.isChecked[1]}
                />
                <span className="design"></span>
              </label>
            </div>
            <input
              className={
                this.state.isOptionEmpty == 1
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="2"
              value={this.state.newquestions.options[1]}
              onChange={this.option}
              placeholder="Option 2"
            />
          </div>
          <div className="editable--questions">
            <div className="quetsion--edit--markquee">
              <label>
                <input
                  type="checkbox"
                  name="3"
                  onChange={(e) => this.handleCheckBox(e)}
                  checked={this.state.isChecked[2]}
                />
                <span className="design"></span>
              </label>
            </div>
            <input
              className={
                this.state.isOptionEmpty == 2
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="3"
              value={this.state.newquestions.options[2]}
              onChange={this.option}
              placeholder="Option 3"
            />
          </div>
          <div className="editable--questions">
            <div className="quetsion--edit--markquee">
              <label>
                <input
                  type="checkbox"
                  name="4"
                  onChange={(e) => this.handleCheckBox(e)}
                  checked={this.state.isChecked[3]}
                />
                <span className="design"></span>
              </label>
            </div>
            <input
              className={
                this.state.isOptionEmpty == 3
                  ? "quedtion--text--fields input-required"
                  : "quedtion--text--fields"
              }
              type="text"
              name="4"
              value={this.state.newquestions.options[3]}
              onChange={this.option}
              placeholder="Option 4"
            />
          </div>

          {this.state.option_emsg && (
            <span className="text-danger" style={{ marginLeft: "3em" }}>
              {this.state.option_emsg}
            </span>
          )}

          <div className="editable-question-submitbox">
            <button
              type="button"
              role="button"
              className="preview--paper--btn font--400 font--14 w-110"
              onClick={this.handlesubmit}
            >
              Add
            </button>
            <button
              role="button"
              type="putton"
              className="preview--button font--400 font--14 w-110 ml-2"
              onClick={this.discard}
            >
              Discard
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Addhardmcq);
